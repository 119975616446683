import './config.css';
import './ol.css';
import GeoTIFF from 'ol/source/GeoTIFF';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';

import GeoJSON from 'ol/format/GeoJSON';

import Map from 'ol/Map';
import TileLayer from 'ol/layer/WebGLTile';
import VectorLayer from 'ol/layer/Vector';

import View from 'ol/View';
import * as proj from 'ol/proj';
import * as extent from 'ol/extent';

import {
	createStringXY
} from 'ol/coordinate';

import {
	Attribution,
	ScaleLine,
	MousePosition,
	ZoomToExtent,
	Rotate,
	defaults as defaultControls
} from 'ol/control';

import {
	Fill,
	Stroke,
	Style
} from 'ol/style';

const hashed = require("hashed");

// ### BEGIN LAYERS

const hillshade_source = new GeoTIFF({
	sources: [{
		url: './data/hillshade_wuppertal.tif'
	}],
	attributions: '<a href="https://www.weber-ing.de">© Weber-Ing</a>',
});
hillshade_source.setAttributions('<a href="https://www.weber-ing.de">© Weber-Ing</a>');

const hillshade = new TileLayer({
	preload: Infinity,
	source: hillshade_source,
});
// [ 361000, 5669000, 383000, 5687000 ]


// const grundkarte = new TileLayer({
// 	opacity: 0.3,
// 	preload: Infinity,
// 	source: new TileWMS({
// 		url: 'https://nrw.wms.webgis-weber-ing.app/geobasis/wms_nw_dtk',
// 		params: {
// 			'LAYERS': 'WMS_NW_DTK',
// 			'TILED': true
// 		},
// 		serverType: 'mapserver'
// 	})
// });

const basemap_de = new TileLayer({
	opacity: 0.5,
	preload: Infinity,
	source: new TileWMS({
		//url: 'https://sgx.geodatenzentrum.de/wms_basemapde',
		url: 'https://sgx.wms.webgis-weber-ing.app/wms_basemapde',
		params: {
			'LAYERS': 'de_basemapde_web_raster_farbe',
			'FORMAT': 'image/jpeg'
			//'TILED': true,
			//'TILEORIGIN': [361000, 5669000]
			//'TILEORIGIN': extent.getBottomLeft(proj.get('EPSG:25832').getExtent()).toString(),
			//'CRS': 'EPSG:25832'
		},
		attributions: '<a href="https://www.geodatenzentrum.de">© basemap.de/BKG</a>',

		serverType: 'mapserver'
	}),
	extent: [ 361000, 5669000, 383000, 5687000 ]
});

const starkregen_source = new TileWMS({
	//url: 'https://sgx.geodatenzentrum.de/wms_starkregen',
	url: 'https://sgx.wms.webgis-weber-ing.app/wms_starkregen',
	params: {
		'LAYERS': 'wasserhoehen_extrem',
		'TILED': true,
		//'TILEORIGIN': extent.getBottomLeft(proj.get('EPSG:25832').getExtent()).toString(),
		//'TILEORIGIN': [361000, 5669000]
		//'CRS': 'EPSG:25832'
	},
	serverType: 'mapserver',
	transition: 0,
});

const starkregen = new TileLayer({
	minZoom: 3.25,
	opacity: 0.8,
	preload: Infinity,
	source: starkregen_source,
	extent: [ 361000, 5669000, 383000, 5687000 ]
});

const update_starkregen_legend = function (resolution) {
	const graphicUrl = starkregen_source.getLegendUrl(resolution)+'&transparent=true&style=STYLE%0ATRANSPARENT%20ON%0AEND';
	const img = document.getElementById('starkregen_legend');
	img.src = graphicUrl;
};

// const hausumringe = new TileLayer({
// 	opacity: 0.6,
// 	preload: Infinity,
// 	source: new TileWMS({
// 		url: 'https://nrw.wms.webgis-weber-ing.app/geobasis/wms_nw_hu',
// 		params: {
// 			'LAYERS': 'WMS_NW_HU',
// 			'TILED': true,
//
// 		},
// 		serverType: 'mapserver'
// 	})
// });
// const buildings = new VectorLayer({
// 	declutter: true,
// 	source: new VectorSource({
// 		format: new GeoJSON(),
// 		projection: "EPSG:25832",
// 		overlaps: false,
// 		url: './data/hausumringe_377_5682.geojson'
// 	}),
// 	//updateWhileAnimating:true,
// 	//updateWhileInteracting:true,
// 	opacity: 0.5,
// 	style: new Style({
// 		stroke: new Stroke({
// 			color: 'blue',
// 			width: 1,
// 		}),
// 		fill: new Fill({
// 			color: 'rgba(0, 0, 255, 0.1)',
// 		}),
// 	})
// });

const layers = [
	hillshade,
	//grundkarte,
	basemap_de,
	starkregen,
	//hausumringe
];

// This layer specifies the initial extent, zoom and rotation
const initial_view = hillshade_source.getView();

// ### END LAYERS



// ### BEGIN CONTROLS

const attributionControl = new Attribution({
	collapsible: true
});


const mousePositionControl = new MousePosition({
	coordinateFormat: createStringXY(4),
	projection: 'EPSG:25832',
	// comment the following two lines to have the mouse position
	// be placed within the map.
	className: 'custom-mouse-position',
	target: document.getElementById('mouse-position'),
});
const scaleControl = new ScaleLine({
	units: 'metric',
	bar: false,
	steps: 1,
	text: false,
	minWidth: 140,
});

const zoomHomeControl = new ZoomToExtent({
	label: "⌂", // a little unicode house U+2302, technical
	//label: '🏠', // a little unicode house U+1F3E0, may be pictograph
	tipLabel: 'Standort Wuppertal',
	extent: [ 376000.0, 5682000.0, 378500.0, 5683000.0 ]
});
const rotateControl = new Rotate({});

const controls = [/*mousePositionControl,*/ attributionControl, scaleControl, zoomHomeControl, rotateControl];

// ### END CONTROLS

const map = new Map({
	controls: controls,
	target: 'map',
	layers: layers,
	view: initial_view,
	maxTilesLoading:256
});

// // ### START PERMALINK GENERATOR

initial_view.then(initial_view_properties => {
	const default_view = new View(initial_view_properties);
	const serialize_view = function(view) {
		return {
			center: view.getCenter(),
			zoom: view.getZoom(),
			rotation: view.getRotation(),
		};
	}
	const serialize_state = function() {
		const view = map.getView();
		return serialize_view(view);
	}
	const default_state = serialize_view(default_view);
	const update_map_on_hash_update = function(state) {
		const current_state = serialize_state();
		const new_state = {...current_state,
			...state
		};
		map.getView().setCenter(new_state.center)
		map.getView().setZoom(new_state.zoom)
		map.getView().setRotation(new_state.rotation)
	}
	const update_hash = hashed.register(default_state, update_map_on_hash_update);
	const update_hash_on_moveend = function() {
		update_hash(serialize_state());
	}
	map.on('moveend', update_hash_on_moveend);
});

// END PERMALINK GENERATOR


// ### START WMS LEGEND

// Initial legend
const resolution = map.getView().getResolution();
update_starkregen_legend(resolution);

// Update the legend when the resolution changes
map.getView().on('change:resolution', function (event) {
  const resolution = event.target.getResolution();
  update_starkregen_legend(resolution);
});

// §§§ END WMS LEGEND


console.log('DEBUG','attribution',basemap_de.getSource().getAttributions());
